import React from "react";
import "./About.scss";
import ankorText from "../../assets/icons/ankorTextGrey.svg";
import photoRight from "../../assets/images/photoRight.jpg";
import photoLeft from "../../assets/images/photoLeft.jpg";

export default function About() {
  return (
    <section className="about" id="about">
      <div className="about__container">
        <div className="about__content">
          <img className="about__logo" src={ankorText} alt="#" />
          <div className="about__gallery">
            <h2 className="about__label about__label-left">
              анкор —<br />
              простір
            </h2>
            <img
              src={photoRight}
              alt="#"
              className="about__img about__img-right"
            />
            <img
              src={photoLeft}
              alt="#"
              className="about__img about__img-left"
            />
            <h2 className="about__label about__label-right">
              вільної
              <br />
              молоді
            </h2>
          </div>
          <h2 className="about__title">Про нас</h2>
          <p className="about__text">
            Табір Анкор - атмосфера свободи, творчості та прийняття, в якій ти завдяки розвагам, практикам медитацій, підтримці наставників зможеш вільно виражати себе, без масок, ігор і обмежень. У нас ти зустрінеш людей, які розділяють твої інтереси, цінності та захоплення неповторною природою  української Буковини. Тебе очікує багаття, світанки на верхівці гори, 24/7 свіже гірське повітря та пригоди, які незабутніми враженнями і безцінним досвідом.
          </p>
        </div>
      </div>
    </section>
  );
}
