import React from "react";
import "./Philosophy.scss";

export default function Philosophy() {
  return (
    <section className="philosophy" id="program">
      <h2 className="philosophy__title">
        <span className="philosophy__title-crossline">наша програма</span>
        <br />
        в нас немає програми
      </h2>
      <h3 className="philosophy__subtitle">наша філософія</h3>
      <div className="philosophy__text">
        В нашому просторі немає стандартної програми. Ми йдемо в ногу з часом і пропонуємо нашим учасникам актуальні та цікаві заняття. <br /> <br /> У нас ти зможеш:  <br />
        <p className="philosophy__listItem">
          - Навчитися новому. Ми проводимо майстер-класи, тренінги та лекції з різних тем, які будуть корисні тобі в житті. Романтичні відносини, стосунки з батьками, наші психологи завжди готові допомогти розібратись в собі та зцілити серце.
        </p>
        <p className="philosophy__listItem">
          - Займатися творчістю. Чоловічі і дівочі майстер-класи, які ви обираєте самостійно.
        </p>
        <p className="philosophy__listItem">
          - Погуляти по природі. Походи з ночівлею в горах, розвідки незнайомих маршрутів. Збір ягід, трав та грибів.
        </p>
        <br />Наші правила:
        <p className="philosophy__listItem">
          - Ти вільний робити все, що не шкодить оточуючим, природі чи цінностям нашого простору.
        </p>
      </div>
    </section>
  );
}
