import React from "react";
import "./LocationReview.scss";
import PhotoSlider from "../PhotoSlider/PhotoSlider";

import photo1 from '../../assets/images/location/Photo1.jpg';
import photo2 from '../../assets/images/location/Photo2.jpg';
import photo3 from '../../assets/images/location/Photo3.jpg';
import photo4 from '../../assets/images/location/Photo4.jpg';
import photo5 from '../../assets/images/location/Photo5.jpg';
import photo7 from '../../assets/images/location/Photo7.jpg';
import photo8 from '../../assets/images/location/Photo8.jpg';
import photo9 from '../../assets/images/location/Photo9.jpg';
import photo10 from '../../assets/images/location/Photo10.jpg';
import photo11 from '../../assets/images/location/Photo11.jpg';
import photo14 from '../../assets/images/location/Photo14.jpg';
import photo15 from '../../assets/images/location/Photo15.jpg';
import photo16 from '../../assets/images/location/Photo16.jpg';
import photo17 from '../../assets/images/location/Photo17.jpg';
import photo18 from '../../assets/images/location/Photo18.jpg';
import photo19 from '../../assets/images/location/Photo19.jpg';


export default function LocationReview({ title, text }) {

  const items = [photo7, photo4, photo15, photo16, photo17 , photo5, photo1, photo8, photo9, photo10, photo11, photo14];

  return (
    <div className="locationReview">
      <div className="gallery__container">
        <PhotoSlider items={items} />
      </div>
      <h3 className="locationReview__title">{title}</h3>
      <p className="locationReview__text">{text}</p>
    </div>
  );
}
