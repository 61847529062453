import React from "react";
import "./ContactPerson.scss";
import BtnGrey from "../BtnGrey/BtnGrey";

import facebookIcon from "../../assets/icons/facebok.svg";
import emailIcon from "../../assets/icons/email.png";

export default function ContactPerson({
	name,
	position,
	photoSrc,
	descr,
	telegramLink,
	facebookLink,
	mail,
}) {
	return (
		<div className="contactPerson">
			<img src={photoSrc} alt="#" className="contactPerson__img" />
			<h2 className="contactPerson__name">
				{name}
				<span className="contactPerson__delim"> | </span>
				<span className="contactPerson__position">{position}</span>
			</h2>
			<p className="contactPerson__descr">{descr}</p>
			<div className="contactPerson__links">
				<div className="contactPerson__btnContainer">
					<BtnGrey text="написати в телеграм" link={telegramLink} />
				</div>
				{facebookLink && <a
					href={facebookLink}
					className="contactPerson__link contactPerson__link-facebook"
				>
					<img src={facebookIcon} alt="#" />
				</a>}
				<a href={mail} className="contactPerson__link contactPerson__link-email">
					<img src={emailIcon} alt="#" />
				</a>
			</div>
		</div>
	);
}
