	import React from "react";
import "./Contacts.scss";
import LogoTitle from "../LogoTitle/LogoTitle";
import ContactPerson from "../ContactPerson/ContactPerson";
import photoLeft from "../../assets/images/contactsLeft.jpg";
import photoRight from "../../assets/images/contactsRight.jpg";

export default function Contacts() {
	return (
		<section className="contacts" id="team">
			<LogoTitle title="контакти" subtitle="як зв’язатися з нашою командою" />
			<div className="contacts__container">
				<ContactPerson
					name="євгеній антонюк "
					position="куратор , а також Педагог, історик,  психолог"
					photoSrc={photoLeft}
					descr="“Люблю пряний присмак кави в горах, вдихаючи повні груди повітря у відчутті безмежної свободи простору і миттєвостей зупинки часу.
					”"
					telegramLink="https://t.me/+380938909927"
					instaLink="mailto:antonukjenia@gmail.com"
				/>
				<ContactPerson
					name="федір мельник"
					position="куратор , а також Психолог, хірург, військовий лікар"
					photoSrc={photoRight}
					descr="“Полюбляю спокій, природу, щирі посмішки, обійми, інтелектуальні ігри, їзду на мотоциклі, змінювати світ.”"
					telegramLink="https://t.me/+380501901123"
					facebookLink="https://www.facebook.com/Melnik.Fedir"
					instaLink="mailto:kvestovich@gmail.com"
				/>
			</div>
		</section>
	);
}
