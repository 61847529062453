import "./App.scss";
import About from "./components/About/About";
import Contacts from "./components/Contacts/Contacts";
import Footer from "./components/Footer/Footer";
import Gallery from "./components/Gallery/Gallery";
import Location from "./components/Location/Location";
import Philosophy from "./components/Philosophy/Philosophy";
import AvailableTours from "./components/AvailableTours/AvailableTours";
import StartScreen from "./components/StartScreen/StartScreen";
import TripInfo from "./components/TripInfo/TripInfo";
import Feedback from "./components/Feedback/Feedback";

export default function App() {
	return (
		<div className="App">
			<StartScreen />
			<TripInfo text="Заїзд 2020, Карпати" />
			<About />
			<Philosophy />
			<TripInfo text="Заїзд 2019, Карпати" />
			<Location />
			<Gallery />
			<AvailableTours/>
			<Feedback
				name="богдан стадник"
				position="учасник"
				text="“Найкраще, що може дати життя - шлях, наповнений пригодами, надійними друзями та випробуваннями, які й формують тебе як людину. Цей табір - став початковою та найважливішою сходинкою в моєму становленні, як особистості та чоловіка. Бо, як усім відомо, перший крок - найважчий, але якщо вже ступив - зупинятись нема сенсу.”"
			/>
			<Contacts />
			<Footer />
		</div>
	);
}
