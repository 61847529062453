import React from "react";
import photo1 from '../../assets/images/gallery/Photo1.jpg';
import photo11 from '../../assets/images/gallery/Photo11.jpg';
import photo15 from '../../assets/images/gallery/Photo15.jpg';
import photo16 from '../../assets/images/gallery/Photo16.jpg';
import photo19 from '../../assets/images/gallery/Photo19.jpg';
import photo2 from '../../assets/images/gallery/Photo2.jpg';
import photo20 from '../../assets/images/gallery/Photo20.jpg';
import photo21 from '../../assets/images/gallery/Photo21.jpg';
import photo23 from '../../assets/images/gallery/Photo23.jpg';
import photo26 from '../../assets/images/gallery/Photo26.jpg';
import photo27 from '../../assets/images/gallery/Photo27.jpg';
import photo28 from '../../assets/images/gallery/Photo28.jpg';
import photo3 from '../../assets/images/gallery/Photo3.jpg';
import photo30 from '../../assets/images/gallery/Photo30.jpg';
import photo31 from '../../assets/images/gallery/Photo31.jpg';
import photo32 from '../../assets/images/gallery/Photo32.jpg';
import photo34 from '../../assets/images/gallery/Photo34.jpg';
import photo35 from '../../assets/images/gallery/Photo35.jpg';
import photo36 from '../../assets/images/gallery/Photo36.jpg';
import photo37 from '../../assets/images/gallery/Photo37.jpg';
import photo38 from '../../assets/images/gallery/Photo38.jpg';
import photo39 from '../../assets/images/gallery/Photo39.jpg';
import photo4 from '../../assets/images/gallery/Photo4.jpg';
import photo5 from '../../assets/images/gallery/Photo5.jpg';
import photo6 from '../../assets/images/gallery/Photo6.jpg';
import photo8 from '../../assets/images/gallery/Photo8.jpg';
import LogoTitle from "../LogoTitle/LogoTitle";
import "./Gallery.scss";

import PhotoSlider from "../PhotoSlider/PhotoSlider";

export default function Gallery() {
	const items = [];

	items.push(photo1, photo2, photo3, photo4, photo5, photo6, photo8, photo11, photo15, photo16, photo19, photo20, photo21, photo23, photo26, photo27, photo28, photo30, photo31, photo32, photo34, photo35, photo36, photo37, photo38, photo39)
		

	return (
		<section className="gallery" id="gallery">
			<LogoTitle title="фотогалерея" subtitle="спогади з усіх років табору" />
			<div className="gallery__container">
				{/* <PhotoSlider items={items} /> */}
				<PhotoSlider items={items} />
			</div>
		</section>
	);
}
