import React from 'react';
import Registration from "../Registration/Registration";
import photo from "../../assets/images/registrationPhoto.jpg";

export default function AvailableTours() {
    return (
        <>
            <Registration {...tours[0]} />
            <Registration {...tours[1]} />
        </>
    )
}

const tours = [
    {
        tourName: "Психологічний табір для підлітків: «РаДіти»",
        registrationInfo: "22 ЧЕРВНЯ - 6 ЛИПНЯ 2024 РОКУ | 13-17 років | 18 500 грн",
        description: 'В подорожі, окрім самостійності, відповідальності та людяності, приділяємо увагу творчості, комунікації, самореалізації).',
        photo: photo,
        registerLink: 'https://forms.gle/HzVMmZCyBG12wNPg7'
    },
    {
        tourName: "Дитячий табір для ДОРОСЛИХ «КАЗКА»",
        registrationInfo: "15 СЕРПНЯ - 25 СЕРПНЯ 2024 РОКУ | 18-60 років | 15 000 грн",
        description: 'В цьому просторі ви маєте можливість повернутись в дитинство, заспівати пісні біля вогнища, заночувати під яскравими зорями, згадати “королівську ніч”, відпочити і вийти за межі буденності. Окрім цього зануритись в себе під час духовних практик, за бажанням ви можете отримати консультацію психологів, та повернутись наповненими і повними сил.',
        photo:  photo,
        registerLink: 'https://forms.gle/CcvVrjz6BZMKd9yf7'
    }
]
