import React from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import "./PhotoSlider.scss";

export default function PhotoSlider({ items }) {

	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		adaptiveHeight: true,
		lazyLoad: 'progressive',
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};

	return (
		<Slider {...settings}>
			{items.map((item, index) => {
				return (
					<div
						className="photoSlider__slide"
						key={index}
					>
						<img className="photoSlider__img" src={item} loading="lazy" alt="" />
					</div>
				);
			})}
		</Slider >
	);
}
