import React from "react";
import "./Location.scss";
import LogoTitle from "../LogoTitle/LogoTitle";
import LocationReview from "../LocationReview/LocationReview";

export default function Location() {
  return (
    <section className="location" id="location">
      <LogoTitle title="Локація" subtitle="комфорт та гори" />
      <div className="location__container">
        <LocationReview
          title="садиба Бульбона"
          text="Починаючи з 2015 року табірного життя ми влаштовували табори в різних куточках Україні, те саме місце яке буде відповідати нашій концепції та філософії виховання підлітків, і дозволить проводити активно час в гармонії з природою та собою.
          На ці табірні з'їзди наш вибір припав на садибу “Бульбона”, що знаходиться у селі Товарниця, 5 км від траси Чернівці–Вижниця–Верховина, 25 км від селища Вижниця, 10 км до найближчого гірськолижного витягу 'Німчич', на висоті 675 м над рівнем моря.
          Діти будуть проживати у будинках розташованих на території в лісі без сусідів і звуків цивілізації. 
          Дівчата будуть проживати в триповерховому дерев'яному будинкові з 2-ма чотиримісними та 2-ма двомісними кімнатами душем, туалетом і усіма зручностями 
          Хлопці проживатимуть на другому поверсі в окремому двоповерховому будинкові в  кімнаті хостельного типу з двоярусними ліжками та душовими та туалетами на території.
          Крім цього на табірному дворищі у нашому розпорядженні будиночок на дереві, колиба, конференц зал, кухня, їдальня з каміном (обідні столи), сауна, спортивний і волейбольний майданчики.
          "
        />
      </div>
    </section>
  );
}
