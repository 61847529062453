import React from "react"
import "./Header.scss"
import ankorText from "../../assets/icons/ankorTextWhite.svg"

export default function Header() {
	return (
		<header className="header">
			<div className="header__content">
				<div className="header__logo">
					<img src={ankorText} alt="#" />
				</div>
				<ul className="header__nav">
					<li className="nav__item"><a href="#about">про нас</a></li>
					<li className="nav__item"><a href="#program">програма</a></li>
					<li className="nav__item"><a href="#location">локація</a></li>
					<li className="nav__item"><a href="#gallery">фотогалерея</a></li>
					<li className="nav__item"><a href="#team">команда</a></li>
				</ul>
				<h2 className="header__contacts"><a href="#team">контакти</a></h2>
			</div>
		</header>
	)
}
