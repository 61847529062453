import React from "react";
import "./Registration.scss";
import LogoTitle from "../LogoTitle/LogoTitle";
import BtnGrey from "../BtnGrey/BtnGrey";

export default function Registration({ tourName, photo, registrationInfo, description, registerLink }) {
	return (
		<section className="registration">
			<LogoTitle title={tourName} subtitle='реєструйся сьогодні' />
			<div className="registration__content">
				<img src={photo} className="registration__img" alt=""/>
				<h3 className="registration__info">{registrationInfo}
				</h3>
				<div className="registration__container">
					<p className="registration__text">
						{description}
					</p>
					<BtnGrey text="форма реєстрації" link={registerLink} />
				</div>
			</div>
		</section>
	);
}
